/* eslint
    func-names: "off"
*/
(function ($) {
  const { getReadableDonationFrequency } = require('./utils/donation-form-helper.js')
  // code for the EFT terms and conditions flow

  // When a new version of the terms and conditions is created, we should _not_
  // update the existing version. We should create a _new_ version in the array
  // below and preserve the old version.
  // Business rule: the terms content container must always be smaller than the terms text, so scrollbar is always enabled. NOTE: different scenarios (ie. c3/c4 and monthly/one-time) have different t&c lengths.
  var eftTermsAndConditionsVersions = [
    {
      version: "v1", // note: for v1 we had only a c4, monthly option.
      html: "<p>I authorize American Civil Liberties Union, Inc. (ACLU) to charge my checking or savings account for the payment of donation(s) I have authorized for the amount I entered or selected on the prior page on or after the indicated date. I further authorize those payments to be made on a recurring basis thereafter, payable on today's date and on or about the same date thereafter, on a monthly basis, for the amount indicated on the prior page. I understand that all such payments will be processed as an Electronic Funds Transfer (EFT), and I certify that I am an authorized signor on this Depository Account.</p><p>I acknowledge and agree that this authorization for the recurring payment of donation(s) will remain in force and effect until I notify the ACLU by calling 888-567-ACLU that I wish to revoke this authorization. Notice to terminate this authorization must be received by the ACLU at least three (3) business days prior to the next scheduled payment date.</p>",
    },
    {
      "v2 - c3 one-time": "<p>I authorize American Civil Liberties Union Foundation, Inc. (ACLU Foundation) to charge my checking or savings account, one-time, for the payment of the donation I have authorized for the amount I entered or selected on the prior page, on or after the indicated date. I understand this payment will be processed as an Electronic Funds Transfer (EFT). I certify that I am an authorized signor on this Depository Account.</p><p>This single entry authorization is to remain in full force and effect until I notify the ACLU Foundation by calling 888-567-ACLU to state that I wish to revoke this authorization. Notice to terminate this authorization must be received by the ACLU Foundation at least three (3) business days prior to the scheduled payment date.</p>",
      "v2 - c4 one-time": "<p>I authorize American Civil Liberties Union, Inc. (ACLU) to charge my checking or savings account, one-time, for the payment of the donation I have authorized for the amount I entered or selected on the prior page, on or after the indicated date. I understand this payment will be processed as an Electronic Funds Transfer (EFT). I certify that I am an authorized signor on this Depository Account.</p><p>This single entry authorization is to remain in full force and effect until I notify the ACLU by calling 888-567-ACLU to state that I wish to revoke this authorization. Notice to terminate this authorization must be received by the ACLU at least three (3) business days prior to the scheduled payment date.</p>",
      "v2 - c3 monthly": "<p>I authorize American Civil Liberties Union Foundation, Inc. (ACLUF) to charge my checking or savings account for the payment of donation(s) I have authorized for the amount I entered or selected on the prior page on or after the indicated date. I further authorize those payments to be made on a recurring basis thereafter, payable on today's date and on or about the same date thereafter, on a monthly basis, for the amount indicated on the prior page. I understand that all such payments will be processed as an Electronic Funds Transfer (EFT), and I certify that I am an authorized signor on this Depository Account.</p><p>I acknowledge and agree that this authorization for the recurring payment of donation(s) will remain in force and effect until I notify the ACLUF by calling 888-567-ACLU that I wish to revoke this authorization. Notice to terminate this authorization must be received by the ACLUF at least three (3) business days prior to the next scheduled payment date.</p>",
      "v2 - c4 monthly": "<p>I authorize American Civil Liberties Union, Inc. (ACLU) to charge my checking or savings account for the payment of donation(s) I have authorized for the amount I entered or selected on the prior page on or after the indicated date. I further authorize those payments to be made on a recurring basis thereafter, payable on today's date and on or about the same date thereafter, on a monthly basis, for the amount indicated on the prior page. I understand that all such payments will be processed as an Electronic Funds Transfer (EFT), and I certify that I am an authorized signor on this Depository Account.</p><p>I acknowledge and agree that this authorization for the recurring payment of donation(s) will remain in force and effect until I notify the ACLU by calling 888-567-ACLU that I wish to revoke this authorization. Notice to terminate this authorization must be received by the ACLU at least three (3) business days prior to the next scheduled payment date.</p>",
      "v2 - c3 one-time - annuities": "<p>I authorize American Civil Liberties Union Foundation, Inc. (ACLU Foundation) to charge my checking or savings account, one-time, for the payment of the donation I have authorized for the amount I entered or selected on the prior page, on or after the indicated date. I understand this payment will be processed as an Electronic Funds Transfer (EFT). I certify that I am an authorized signor on this Depository Account.</p><p>This single entry authorization is to remain in full force and effect until I notify the ACLU Foundation by calling 888-567-ACLU to state that I wish to revoke this authorization to make my donation from the provided account. Notice to terminate the authorization must be received by the ACLU Foundation at least three (3) business days from today’s date.  Please note that revoking such authorization applies only to the payment of the donation from the provided account and does not serve to terminate or void the charitable gift annuity agreement and any other related agreements.</p>",
    },
  ]

  function getEftTaxDesignation() {
    const activeEftGatewayMachineName = Drupal.settings.active_gateways['bank account'].gateway.split('|')[1]
    return activeEftGatewayMachineName.includes('_natu_') ? 'c4' : 'c3'
  }

  // We need to show and record different t&cs depending on if this is a c3 or c4 donation
  // and whether it is a one-time or monthly donation (4 standard permutations).
  //
  // There are also edge cases where we need to adjust the language for a specific form.
  // When this occurs, the additional information is appended to the end of the key.
  function getCurrentEftTermsAndConditions() {
    const latestVersion = 'v2' // should only be set to v2 or higher, not v1
    const taxDesignation = getEftTaxDesignation()
    const donationFrequency = getReadableDonationFrequency()
    let eftVersionKey = `${latestVersion} - ${taxDesignation} ${donationFrequency}`
    // the annuities form has special t&c, so we override the normal value for just that form
    if ($("body.page-node-81139").length) {
      eftVersionKey += ' - annuities'
    }

    // always return the most recent version
    return {
      version: eftVersionKey,
      html: eftTermsAndConditionsVersions[eftTermsAndConditionsVersions.length - 1][eftVersionKey],
    }
  }

  function showDonateButton(reviewTermsButton) {
    reviewTermsButton.style.display = 'none'
    document.querySelector('.complete').style.display = 'block'
  }

  function configureTermsModal(eftTermsModalContainer, reviewTermsButton, eftTermsAndConditionsHiddenField) {
    const eftTermsModal = $('.eft-terms-modal')
    const openTermsButton = $(reviewTermsButton)
    const acceptButton = $('.accept-terms')
    const declineButton =  $('.decline-terms')
    const termsContentArea = $('.read-terms')
    const termsError = $('.terms-error')
    var previousElement = null

    // populate terms and disable accept button by default
    termsContentArea.html(getCurrentEftTermsAndConditions().html)
    // disable button with class so VO readers wont skip the button and it does not the disable click event
    acceptButton.attr('aria-disabled', true).addClass('disabled')
    const useDifferentPaymentText = $('.use-different-payment')

    // Insert the EFT modal html in page wrapper
    $(eftTermsModalContainer).insertBefore($('.sample-check').parent())


    function closeReviewTermsModal() {
      $('body').removeClass('modal-popup')
      previousElement.focus();
      previousElement = null;
    }

    function openReviewTermsModal() {
      $('body').addClass('modal-popup')
      // store the element that triggered the modal
      // so we can return the focus when the modal is closed
      previousElement = ( document.activeElement || document.body );
      trapModalFocus();
    }

    declineButton.click(function() {
      eftTermsAndConditionsHiddenField.value = ''
      useDifferentPaymentText.addClass('show')
      closeReviewTermsModal()
    })

    $(useDifferentPaymentText).click(Drupal.goToFirstStep)

    eftTermsModal.click(function(e){
      // Allows user to click outside of the modal content
      //display area to close the modal
      if(e.target == e.currentTarget)  {
        closeReviewTermsModal()
      }
    })
    openTermsButton.click(function(e){
      var sampleCheckElem = document.querySelector('.sample-check')
      sampleCheckElem.parentElement.insertBefore(useDifferentPaymentText[0], sampleCheckElem.nextSibling)
      openReviewTermsModal()
    })
    // Allows the user to close the modal box, when user clicks on (x) button
    $('.eft-terms-modal-close').click(function(e){
      closeReviewTermsModal()
    })
    termsContentArea.scroll(function(e) {
      // business rule: only enable the accept button upon scroll to bottom of terms content
      if ((e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight){
        acceptButton.attr('aria-disabled', false).removeClass('disabled')
        termsContentArea.removeClass('error')
        termsError.hide()
      }
    })
    acceptButton.click(function(e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault()
        termsContentArea.addClass('error')
        termsError.show()
        return false
      }
      eftTermsAndConditionsHiddenField.value = getCurrentEftTermsAndConditions().version

      var termsAcceptedText = document.querySelector('.terms-accepted')
      var sampleCheckElem = document.querySelector('.sample-check')
      sampleCheckElem.parentElement.insertBefore(termsAcceptedText, sampleCheckElem.nextSibling)
      termsAcceptedText.style.display = 'block'
      useDifferentPaymentText.removeClass('show')
      showDonateButton(reviewTermsButton)
      previousElement = document.querySelector('.complete')
      closeReviewTermsModal()
    })
  }

  function configureTermsAndConditionButton(eftTermsAndConditionsHiddenField, reviewTermsButton) {
    $(document).on('step_updated', function (event, currentStep) {
      if (currentStep === 3) {
        if ((eftTermsAndConditionsHiddenField.value === '') && (document.querySelector('input.fundraiser-payment-methods:checked').value === 'bank account')) {
          var progressButtonsParent = document.querySelector('fieldset.step-3 .progress-buttons .progress-wrap')
          var backButton = progressButtonsParent.querySelector('.back')
          progressButtonsParent.insertBefore(reviewTermsButton, backButton)
          reviewTermsButton.style.display = 'block'
          document.querySelector('.complete').style.display = 'none'
        } else {
          showDonateButton(reviewTermsButton)
        }
      }
    });
  }

  function configureTermsValidation(eftTermsAndConditionsHiddenField) {
    $('.webform-client-form').submit(function (e) {
        // only submit terms of service field value with donation if it is a bank payment
        // while it is technically true that the user accepted the t&c, it may cause confusion on the
        // data side if non-eft donations are seen with EFT t&c signed.
        const currentPaymentMethod = $(this).find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.checked == true });
        if (currentPaymentMethod.val() !== "bank account") {
          $(eftTermsAndConditionsHiddenField).val("")
        }
    });
  }

  window.addEventListener('DOMContentLoaded', function () {
    var eftTermsAndConditionsHiddenField = document.querySelector('input[type="hidden"][name="submitted[accepted_eft_terms_and_conditions]"]');
    var reviewTermsButton = document.querySelector('.review-terms')
    var eftTermsModalContainer = document.querySelector('#eft-terms-container')

    if (eftTermsAndConditionsHiddenField && reviewTermsButton && eftTermsModalContainer && !!Drupal.settings.active_gateways['bank account']) {
      configureTermsModal(eftTermsModalContainer, reviewTermsButton, eftTermsAndConditionsHiddenField)
      configureTermsAndConditionButton(eftTermsAndConditionsHiddenField, reviewTermsButton)
      configureTermsValidation(eftTermsAndConditionsHiddenField)
    }
  });

  // trap focus in modal once it's opened for accessibility
  function trapModalFocus(){
    const focusableElements = 'button, [href], [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector('.eft-terms-modal');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    document.addEventListener('keydown', function(e) {
      let isTabPressed = e.key === 'Tab' || e.code === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
      // if shift key pressed for shift + tab combination on the first focusable element,
      // return focus to the last focusable element
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
      } else {
        // if tab key is pressed on the last focusable element,
        // then return focus to the first focusable element
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });

    firstFocusableElement.focus();
  }

})(jQuery);
