/*
 * Contains helper variables and functions for recurring springboard
 * calculations and constants.
 *
 * Before adding new things to this file, be sure to check that
 * Drupal.settings doesn't already have what you need. You can see
 * what's in it by typing `Drupal.settings` in the devtools console.
 * That object has sub-objects that contain a lot of useful values.
 */
/**
 * Donation Types: one-time, monthly, annual
 * Donation Frequency: NO_RECURR, recurs, sb_fs_annually
 * Donation Form Types: switcher, one-time only, monthly only, annual only 
 * Donation Form Wrapper Layouts: singlestep, multistep
 * Note: 
 *  The donation form DOM changes depending on what donation frequencies are enabled. 
 *  If one-time, monthly and annual are enabledd (aka switcher) the classes and ids available are different.
 *  In some cases this doesn't matter in other cases it does.
 *  There is a spin-off ticket to have the helpers match these expectations of different form types DOM elements
 *  available for the developer. Improve self-documentation of the variable names.
 */
(function ($) {
  const PAYMENT_TYPES_QUERY = ".form-item-submitted-extra-fields-payment-options, input.apple-pay-button";
  // the donation frequency radio button group ID for switcher forms only: NO_RECURR, recurs or sb_fs_annually
  const SWITCHER_DONATION_RADIO_BUTTON_ID = "#webform-component-donation--recurs-monthly";

  // only for switcher forms
  function getOneTimeDonationRadioButton(){
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="NO_RECURR"]');
  }

  // only for switcher forms
  function getMonthlyDonationRadioButton(){
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="recurs"]');
  }

  // only for switcher forms
  function getAnnualDonationRadioButton() {
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="sb_fs_annually"]');
  }

  // only for switcher forms
  function isOneTimeDonationRadioButtonSelected(){
    return getOneTimeDonationRadioButton().is(':checked');
  }
  // only for switcher forms
  function isAnnualDonationRadioButtonSelected(){
    return getAnnualDonationRadioButton().is(':checked');
  }
  // only for switcher forms
  function isMonthlyDonationRadioButtonSelected(){
    return getMonthlyDonationRadioButton().is(':checked');
  }
  // only for switcher forms
  function isOneTimeOtherRadioSelected() {
    return $('input:radio[name="submitted[donation][amount]"]:checked').val() == 'other';
  }
  // only for switcher forms
  function isMonthlyOtherRadioSelected() {
    return $('input:radio[name="submitted[donation][recurring_amount]"]:checked').val() == 'other';
  }
  // only for switcher forms
  function isAnnualOtherRadioSelected() {
    return $('input:radio[name="frequencies[sb_fs_annually_amount]"]:checked').val() == 'other';
  }
  // only for switcher forms
  function selectOneTimeDonationRadioButton() {
    return getOneTimeDonationRadioButton().click()
  }
  // only for switcher forms
  function selectAnnualDonationRadioButton() {
    return getAnnualDonationRadioButton().click()
  }

  /* 
   * Returns the currently selected donation frequency. For a switcher and non switcher forms.
   * The values are the default Springboard configuration. Not the user friendly readable format. 
   *  ONE_TIME_FREQUENCY: "NO_RECURR",
   *  MONTHLY_FREQUENCY: "recurs",
   *  ANNUAL_FREQUENCY: "sb_fs_annually",
   */
  function getDonationFrequency() {
        const frequencyInputType = $('input[name="submitted[donation][recurs_monthly]"]').attr('type')

        // switcher forms keep the donation type in radio buttons
        // non-switcher forms keep the donation type in a hidden input field
        if (frequencyInputType == 'radio') {
          return $('input[type="radio"][name="submitted[donation][recurs_monthly]"]:checked').val()
        } else {
          // non switcher forms regardless of the frequency type: 
          // one-time only, monthly only, annual only
          // forms have the same donation amounts and other input text field as a one-time only form
          return $('input[name="submitted[donation][recurs_monthly]"]').val()
        }
  }

  function isSwitcherForm() {
    const frequencyInputType = $('input[name="submitted[donation][recurs_monthly]"]').attr('type')

    // switcher forms keep the donation type in radio buttons
    // non-switcher forms keep the donation type in a hidden input field
    if (frequencyInputType == 'radio') {
      return true
    } else {
      return false
    }
  }

  function getReadableDonationFrequency() {
    const readableMap = {
      'sb_fs_annually': 'annual',
      'recurs': 'monthly',
      'NO_RECURR': 'one-time',
    }

    let frequency = getDonationFrequency()
    
    return readableMap[frequency]
  }

  module.exports = {
    ONE_TIME_FREQUENCY: "NO_RECURR",
    MONTHLY_FREQUENCY: "recurs",
    ANNUAL_FREQUENCY: "sb_fs_annually",
    SWITCHER_DONATION_RADIO_BUTTON_ID,
    PAYMENT_TYPES_QUERY,
    getOneTimeDonationRadioButton,
    getMonthlyDonationRadioButton,
    getAnnualDonationRadioButton,
    isOneTimeDonationRadioButtonSelected,
    isMonthlyDonationRadioButtonSelected,
    isAnnualDonationRadioButtonSelected,
    isMonthlyOtherRadioSelected,
    isAnnualOtherRadioSelected,
    isOneTimeOtherRadioSelected,
    selectOneTimeDonationRadioButton,
    selectAnnualDonationRadioButton,
    getDonationFrequency,
    isSwitcherForm,
    getReadableDonationFrequency,
  };
})(jQuery);
